import React from 'react'
import { navigate, graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Posts = ({ data, pageContext }) => {
  const posts = data.allStrapiArticle.edges
  const { humanPageNumber, basePath, numberOfPages } = pageContext

  return (
    <Layout>
      <SEO title={'Page ' + humanPageNumber + ' of ' + numberOfPages + ' - All Posts'} />
      <div className='cards-container'>
        {
          posts.map(post => {
            const date = new Date(post.node.updatedAt)
            const ttr = post.node.timetoread.childMdx.timeToRead
            return (
              <div className='card-wrapper' key={post.node.slug}>
                <Link to={'/' + post.node.slug}>
                  <div className='card-image-container'>
                    {/* Dynamic image transformation query url + ?tr=w-10,h-50 */}
                    <img className='card-image' src={post.node.hero_image.url + '?tr=w-280'} ></img>
                    <div className='card-details-container'>
                      <h5 >{post.node.title}</h5>
                      <small>{`${ttr} min read`} | {date.toLocaleDateString()}</small>
                      <small></small>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })
        }
      </div>
      <div className='pagination-container'>
        <ul class="uk-pagination posts-pagination" data-uk-margin>
          <li><a onClick={() => navigate(`/posts/${humanPageNumber === 1 || 2 ? '' : humanPageNumber - 1}`)}><span data-uk-pagination-previous></span></a></li>
          {
            Array.from({ length: numberOfPages }, (_, i) => {
              return (
                <li className={`${i + 1 === humanPageNumber ? 'uk-active' : ''}`} key={`pagination-${i + 1}`}  ><a onClick={() => navigate(`/posts/${i + 1 === 1 ? '' : i + 1}`)}>{i + 1}</a></li>
              )
            })
          }
          <li><a onClick={() => navigate(`/posts/${humanPageNumber === numberOfPages ? numberOfPages : humanPageNumber + 1}`)}><span data-uk-pagination-next></span></a></li>
        </ul>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allStrapiArticle(filter: { status: { eq: "published" } }
        limit: $limit
        skip: $skip) {
        edges {
          node {
            slug
            title
            updatedAt
            timetoread: childStrapiArticleContent {
              childMdx {
                timeToRead
              }
            }
            hero_image {
              url
            }
          }
        }
      }
  }
`

export default Posts
